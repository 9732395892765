import React from 'react';
import i18n from '../../../../locale';
import QuestionTitle from '../QuestionTitle';
import Next from '../Next';
import styles from './styles.module.sass';

export default ({ formData, setFormData, onNext }) => (
  <>
    <QuestionTitle text={`1. ${i18n('What is the number plate of your car?')}`}/>
    <div className={styles.container}>
      <input type='text' className={styles.input}
        defaultValue={formData}
        onChange={e => setFormData(e.target.value)}
        placeholder={i18n('Number plate')}/>
    </div>
    <Next onClick={onNext}
      disabled={!formData || formData < 0}/>
  </>
)