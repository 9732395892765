import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Creatable from 'react-select/creatable';
import i18n from '../../../../locale';
import Button from '../../../../components/Button';
import Typography from '../../../../components/Typography';
import QuestionTitle from '../QuestionTitle';
import styles from './styles.module.sass';

const garrages = require('../../../../data/locations.json').map(g => ({
  label: g.name,
  value: g.name
}));

export default ({ onNext, formData, setFormData }) => {
  const [ selectingGarage, setSelectingGarage ] = React.useState(false);

  return (
    <>
      <QuestionTitle 
        text={`2. ${i18n('Do you want to select a preferred garage?')}`}
        subtext={i18n('One of the unique aspects of our service is the possibility to select a preferred garage to make vehicle management easier for you.')}/>
      {selectingGarage || formData
      ? <>
          <div className={styles.container}>
            <Typography variant='subtitle2'
              align='center'
              weight='light'
              className={styles.inputTooltip}>
              {i18n('Write the name of the garage or select it from the list')}
            </Typography>
            <Creatable styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderWidth: '2px',
                  borderRadius: '8px',
                  borderColor: state.isFocused ? '#2f60df' : '#9f9f9f'
                }),
                option: provided => ({
                  ...provided,
                  textAlign: 'left'
                })
              }}
              formatCreateLabel={input => `${i18n('Add')} "${input}"`}
              placeholder={i18n('Name')}
              className={styles.select}
              onChange={e => setFormData(e)}
              defaultValue={formData}
              options={garrages}/>
          </div>
          <Grid container className={styles.buttonsContainer}>
            <Grid item container xs={12} sm={6} md={6}
              className={clsx(
                styles.buttonContainer,
                styles.buttonContainerLeft
              )}
              justifyContent='flex-end'>
              <div className={styles.button}>
                <Button onClick={() => {
                    setFormData(null);
                    onNext();
                  }}
                  className={styles.button}>
                  {i18n('No garage')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}
              className={clsx(
                styles.buttonContainer,
                styles.buttonContainerRight
              )}>
              <div className={styles.button}>
                <Button onClick={onNext}
                  className={styles.button}>
                  {i18n('Next')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      : <Grid container className={styles.buttonsContainer}>
          <Grid item container xs={12} sm={6} md={6}
            className={clsx(
              styles.buttonContainer,
              styles.buttonContainerLeft
            )}
            justifyContent='flex-end'>
            <div className={styles.button}>
              <Button onClick={onNext}
                className={styles.button}>
                {i18n('NO')}
              </Button>
              <Typography align='center'
                variant='subtitle2'
                color='dark'
                weight='light'
                className={styles.buttonUndertext}>
                {i18n('No, I do not want to select a garage.')}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}
            className={clsx(
              styles.buttonContainer,
              styles.buttonContainerRight
            )}>
            <div className={styles.button}>
              <Button onClick={() => setSelectingGarage(true)}
                className={styles.button}>
                {i18n('YES')}
              </Button>
              <Typography align='center'
                variant='subtitle2'
                color='dark'
                weight='light'
                className={styles.buttonUndertext}>
                {i18n('Yes, I do have a preferred garage')}
              </Typography>
            </div>
          </Grid>
        </Grid>}
    </>
  );
}