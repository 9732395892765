import React from 'react';
import clsx from 'clsx';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import QuestionTitle from '../QuestionTitle';
import Next from '../Next';
import styles from './styles.module.sass';

export default ({
  email,
  phone,
  name,
  setEmail,
  setPhone,
  setName,
  onNext
}) => (
  <>
    <QuestionTitle
      text={`${i18n('Ready to get started?')}`}
      subtext={i18n('We will send you a confirmation email once we process your sign up form.')}/>
    <div className={styles.container}>
      <input type='email' className={styles.input}
        defaultValue={email}
        onChange={e => setEmail(e.target.value)}
        placeholder={i18n('Email address')}/>
    </div>
    <div className={clsx(
        styles.container,
        styles.phoneContainer
      )}>
      <input type='text' className={styles.input}
        defaultValue={name}
        onChange={e => setName(e.target.value)}
        placeholder={i18n('Name')}/>
    </div>
    <div className={clsx(
        styles.container,
        styles.nameContainer
      )}>
      <input type='tel' className={styles.input}
        defaultValue={phone}
        onChange={e => setPhone(e.target.value)}
        placeholder={i18n('Telephone number (optional)')}/>
    </div>
    <Typography className={styles.termsContainer}
      variant='subtitle1'
      weight='light'
      color='dark'>
      {i18n('By clicking on \'Sign up\', you consent to OBI Plus ApS storing and processing the personal information you have provided above to deliver what you have requested.')}
    </Typography>
    <Next disabled={!email || !name}
      text={i18n('Sign up')}
      onClick={onNext}/>
  </>
)